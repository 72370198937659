<template>
    <div class="container-lg my-3 flex-fill body-container" id="accueil">
        <div
            v-if="isFromTY"
            class="alert container-lg d-flex align-items-center rounded-1"
            :class="`alert-primary-${client} text-desoncary-${client}`"
            role="alert"
        >
            <i class="fa-solid fa-check me-3"></i>

            <div v-if="isConfirm">Merci pour votre inscription, un email de confirmation vous a été envoyé.</div>
            <div v-else>Merci, votre réponse a été prise en compte.</div>
        </div>

        <div
            v-if="isErrorUrl"
            class="alert alert-danger container-lg d-flex align-items-center rounded-1 text-white"
            role="alert"
        >
            <i class="fa-solid fa-times me-3"></i>
            <div>Url invalide</div>
        </div>

        <img :src="afficheUrl" class="mw-100 w-100 shadow-lg border border-5 border-primary-light rounded" alt="" />
    </div>
</template>

<script>
import { getCurrentClient, getAfficheUrl } from "@/services/client";

export default {
    components: {},
    data() {
        const _client = getCurrentClient();

        let _isFromTY = localStorage.fromty && JSON.parse(localStorage.fromty);
        localStorage.fromty = false;

        let _isConfirm = localStorage.p && JSON.parse(localStorage.p);
        localStorage.p = false;

        let _isErrorUrl = localStorage.errorUrl && JSON.parse(localStorage.errorUrl);
        localStorage.errorUrl = false;

        return {
            isFromTY: _isFromTY,
            isConfirm: _isConfirm,
            isErrorUrl: _isErrorUrl,
            client: _client,
            afficheUrl: getAfficheUrl(_client),
        };
    },
};
</script>
