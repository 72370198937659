import Vue from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import router from "./plugins/router";
import "./assets/styles/site.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AsyncComputed from "vue-async-computed";
import { getCurrentClient, } from "./services/client";


Vue.use(AsyncComputed);
Vue.use(VueGtag, {
    config: { id: "G-F2QZXD97FE" },
});

Vue.config.productionTip = false;

document.addEventListener("DOMContentLoaded", async function () {
    new Vue({
        router,
        render: (h) => h(App),
    }).$mount("#app");

    var head = document.getElementsByTagName("head")[0];

    var client = getCurrentClient();

    // ajout du favicon selon le client
    var link = document.createElement("link");
    link.rel = "icon";
    link.href = `/favicon-${client}.ico`;
    head.appendChild(link);

    // ajout du titre selon le client
    var title = document.createElement("title");
    if (client == "myral") title.textContent = "Myral - Inauguration";
    else if (client == "uniso") title.textContent = "UNISO - Réunion du Réseau";
    else if (client == "ifm") title.textContent = "IFM - Portes ouvertes";
    head.appendChild(title);
});

