<template>
    <div class="inscription container-xl mx-auto d-flex flex-column my-4 flex-fill body-container">
        <div v-if="isLoading" class="loader d-flex-center">
            <div class="d-flex-center flex-column bg-opacity-75 rounded p-3 pt-4--5" :class="`bg-primary-${client}`">
                <div class="spinner-border" :class="`text-secondary-${client}`" role="status"></div>
                <strong class="mt-3" :class="`text-secondary-${client}`">Envoi du formulaire en cours...</strong>
            </div>
        </div>

        <div class="row">
            <div class="col-12 d-flex flex-column align-items-center">
                <img :src="logoUrl" class="inscription__logo mb-4--5" alt="" />

                <div class="bg-white p-3 shadow bg-opacity-100 rounded">
                    <h1 :class="`bg-secondary-${client}`" class="w-100 rounded mb-3 py-3">Formulaire de réponse</h1>

                    <form method="POST" class="inscription__form needs-validation" novalidate>
                        <div class="row g-3">
                            <!-- Participation -->
                            <div class="col-12 d-flex-center py-3">
                                <span class="fs-5">Je souhaite participer à l'événement :</span>
                                <button
                                    type="button"
                                    class="btn btn-lg mx-3"
                                    v-bind:class="[
                                        form_data.is_participating
                                            ? `btn-primary-${client}`
                                            : `btn-outline-primary-${client}`,
                                    ]"
                                    @click="participate(true)"
                                    for="chk_yes"
                                >
                                    Oui
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-lg"
                                    v-bind:class="[
                                        form_data.is_participating
                                            ? `btn-outline-secondary-${client}`
                                            : `btn-secondary-${client}`,
                                    ]"
                                    @click="participate(false)"
                                    for="chk_no"
                                >
                                    Non
                                </button>
                            </div>

                            <!-- Nom -->
                            <div class="col-12 col-sm-6">
                                <div class="form-floating">
                                    <input
                                        class="form-control text-uppercase"
                                        type="text"
                                        v-model="form_data.last_name"
                                        id="last_name"
                                        name="last_name"
                                        placeholder="Dupond"
                                        required
                                    />
                                    <label for="last_name">Nom*</label>
                                    <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                                </div>
                            </div>

                            <!-- Prenom -->
                            <div class="col-12 col-sm-6">
                                <div class="form-floating">
                                    <input
                                        class="form-control text-capitalize"
                                        type="text"
                                        v-model="form_data.first_name"
                                        id="first_name"
                                        name="first_name"
                                        placeholder="Alain"
                                        required
                                    />
                                    <label for="first_name">Prénom*</label>
                                    <div class="invalid-feedback">Veuillez renseigner votre prénom</div>
                                </div>
                            </div>

                            <!-- Email -->
                            <div class="col-12 col-sm-7">
                                <div class="form-floating">
                                    <input
                                        class="form-control"
                                        type="email"
                                        v-model="form_data.email"
                                        id="email_address"
                                        name="email_address"
                                        placeholder="nom@exemple.fr"
                                        :required="form_data.is_participating"
                                    />
                                    <label for="email_address"
                                        >Adresse email{{ form_data.is_participating ? "*" : "" }}</label
                                    >
                                    <div class="invalid-feedback">Adresse email invalide</div>
                                </div>
                            </div>

                            <!-- Telephone -->
                            <div class="col-12 col-sm-5">
                                <div class="form-floating">
                                    <input
                                        class="form-control"
                                        type="tel"
                                        v-model="form_data.phone_number"
                                        id="phone_number"
                                        name="phone_number"
                                        placeholder="0701020304"
                                        :required="form_data.is_participating"
                                    />
                                    <label for="phone_number"
                                        >Numéro de téléphone{{ form_data.is_participating ? "*" : "" }}</label
                                    >
                                    <div class="invalid-feedback">Veuillez renseigner votre numéro de téléphone</div>
                                </div>
                            </div>

                            <!-- Allergies -->
                            <div class="col-12" v-if="form_data.is_participating && client == 'uniso'">
                                <div class="form-floating">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="form_data.allergies"
                                        id="allergies"
                                        name="allergies"
                                        placeholder="Allergies"
                                    />
                                    <label for="allergies">Allergies ou régime alimentaire à signaler</label>
                                </div>
                            </div>

                            <!-- Accompagné -->
                            <div class="col-12 ps-2" v-if="form_data.is_participating && type_invitation > 0">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        v-model="form_data.is_accompanied"
                                        v-on:change="isAccompaniedChanged"
                                        type="checkbox"
                                        id="is_accompanied"
                                    />
                                    <label class="form-check-label" for="is_accompanied"
                                        >Je souhaite venir accompagné(e)</label
                                    >
                                </div>
                            </div>

                            <!-- Accompagnants -->
                            <div
                                class="col-12 row mx-0 g-2"
                                v-for="(item, i) in form_data.accompagnants"
                                v-bind:key="i"
                            >
                                <!-- Row number -->
                                <div
                                    :class="`text-secondary-${client}`"
                                    class="gy-1 col col-sm-auto fs-4 d-flex align-items-start align-items-sm-center fw-bold"
                                    v-if="type_invitation == 2"
                                >
                                    <span class="text-primary">#</span>{{ i + 1 }}
                                </div>
                                <!-- Boutton suppression -->
                                <div
                                    :class="`link-secondary-${client}`"
                                    class="gy-1 col-auto d-flex-center fw-bold order-sm-last"
                                    v-if="type_invitation == 2 && form_data.accompagnants?.length > 1"
                                >
                                    <i
                                        class="cursor-pointer fa-solid fa-trash-alt me-1 fa-lg"
                                        @click="() => removeAccompagnant(i)"
                                    />
                                </div>
                                <!-- Nom accompagnant -->
                                <div class="gy-1 col-12 col-sm" v-bind:key="`c3_${i}`">
                                    <div class="form-floating">
                                        <input
                                            class="form-control text-uppercase"
                                            type="text"
                                            v-model="item.last_name"
                                            v-bind:id="`last_name_${i}`"
                                            v-bind:name="`last_name_${i}`"
                                            placeholder="Dupont"
                                            required
                                        />
                                        <label v-bind:for="`last_name_${i}`">Nom accompagnant(e)*</label>
                                    </div>
                                </div>
                                <!-- Prénom accompagnant -->
                                <div class="gy-1 col-12 col-sm" v-bind:key="`c2_${i}`">
                                    <div class="form-floating">
                                        <input
                                            class="form-control text-capitalize"
                                            type="text"
                                            v-model="item.first_name"
                                            v-bind:id="`first_name_${i}`"
                                            v-bind:name="`first_name_${i}`"
                                            placeholder="Dupont"
                                            required
                                        />
                                        <label v-bind:for="`first_name_${i}`">Prénom accompagnant(e)*</label>
                                    </div>
                                </div>
                                <div class="col-12 order-last d-sm-none" v-if="form_data.accompagnants?.length > i + 1">
                                    <div class="opacity-75 bg-primary mt-2" style="height: 2px"></div>
                                </div>
                            </div>

                            <!-- Boutton ajouter un accompagnant -->
                            <div
                                class="col-12 text-center g-2"
                                v-if="
                                    type_invitation == 2 &&
                                    form_data.is_accompanied &&
                                    form_data.accompagnants?.length < max_mates
                                "
                            >
                                <button
                                    @click="addNewAccompagnant"
                                    class="btn"
                                    :class="`btn-outline-secondary-${client}`"
                                    type="button"
                                >
                                    <i class="fa-solid fa-plus me-2 fa-sm" />
                                    <span>Ajouter un accompagnant</span>
                                </button>
                            </div>
                        </div>

                        <button
                            @click="register"
                            class="w-100 btn btn-lg mt-3"
                            :class="`btn-primary-${client}`"
                            type="button"
                        >
                            Valider
                        </button>

                        <div class="mt-2 small" :class="`text-primary-${client}`">* champs obligatoires</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import { getCurrentClient, getLogoUrl } from "@/services/client";
const Myral_SaveRegistration = httpsCallable(functions, "Myral_SaveRegistration");
const Myral_CheckTypeInvitation = httpsCallable(functions, "Myral_CheckTypeInvitation");

const empty_form = {
    is_participating: true,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    is_accompanied: false,
    accompagnants: [],
    allergies: "",
};

export default {
    props: {
        token: null,
    },
    created() {
        this.cleanData();
    },
    data() {
        const _client = getCurrentClient();

        return {
            isLoading: false,
            hasError: false,
            max_mates: 3,
            form_data: {
                is_participating: true,
                last_name: "Modin",
                first_name: "Damien",
                email: "damien+sb@innov-events.fr",
                phone_number: "0789215568",
                is_accompanied: true,
                accompagnants: [{ first_name: "Jacques", last_name: "Onpagne" }],
                allergies: "arrachide et corriandre",
            },
            type_invitation: 0, // 0|1|2
            client: _client,
            logoUrl: getLogoUrl(_client),
        };
    },
    methods: {
        participate(is_participating) {
            this.form_data.is_participating = is_participating;

            if (!is_participating) {
                this.form_data.is_accompanied = false;
                this.isAccompaniedChanged();
            }
        },
        isAccompaniedChanged() {
            if (this.form_data.is_accompanied) this.addNewAccompagnant();
            else this.form_data.accompagnants = [];
        },
        addNewAccompagnant() {
            if (this.form_data.accompagnants.length < this.max_mates)
                this.form_data.accompagnants.push({ first_name: "", last_name: "" });
        },
        removeAccompagnant(i) {
            this.form_data.accompagnants.splice(i, 1);
        },
        async register() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");

                this.form_data.invitation_type = this.type_invitation;
                this.form_data.client = this.client;
                if (form.checkValidity()) {
                    await Myral_SaveRegistration(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                localStorage.p = this.form_data.is_participating;
                                this.cleanData();
                                router.push({ name: "done" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form?.classList.remove("was-validated");
        },
    },
    asyncComputed: {
        async type_invitation() {
            var _type_invitation = -1;

            if (this.client == "myral") _type_invitation = 1;
            else if (this.client == "ifm") _type_invitation = 2;
            else if (this.client == "uniso") {
                if (this.token) {
                    _type_invitation = await Myral_CheckTypeInvitation(this.token)
                        .then((result) => {
                            const data = result.data;
                            if (data == -1) {
                                localStorage.errorUrl = true;
                                router.push({ name: "home" });
                            } else {
                                return data;
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            router.push({ name: "home" });
                        });
                } else _type_invitation = 0;
            }

            return _type_invitation;
        },
    },
};
</script>

<style lang="scss" scoped>
$primary: #3f4453;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $primary, $alpha: 0.5);
}
</style>
