const host = window.location.host;
var _client = host.split(".")[host.startsWith("www") ? 1 : 0];

if (process.env.NODE_ENV == "development") {
    // _client = "uniso";
    // _client = "myral";
    _client = "ifm";
}

const getCurrentClient = () => _client;
const getAfficheUrl = () => require(`@/assets/img/affiche-${_client}.png`);
const getLogoUrl = () => require(`@/assets/img/logo-${_client}.png`);
const getBgUrl = () => require(`@/assets/img/bg-site-${_client}.svg`);
const getPrimaryColor = () => {
    if (_client == "myral") return "#ef7a2b";
    else if (_client == "uniso") return "#b0c700";
    else if (_client == "ifm") return "#b0c700";
};
const getSecondaryColor = () => {
    if (_client == "myral") return "#404042";
    else if (_client == "uniso") return "#393a41";
    else if (_client == "ifm") return "#393a41";
};

export {
    getCurrentClient,
    getAfficheUrl,
    getLogoUrl,
    getBgUrl,
    getPrimaryColor,
    getSecondaryColor
};
